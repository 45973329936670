@import '~@/assets/styles/vars.scss';

.footer {
  margin-top: auto;
  background: linear-gradient(96.85deg, #1f3e2c -8.44%, #1a3435 102.66%);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
  padding-top: 45px;
  padding-bottom: 63px;

  &-top {
    max-width: 1300px;
    margin: auto;
    padding-bottom: 44px;
    justify-content: space-between;
    align-items: center;

    &__right {
      .mail-list {
        margin-right: 60px;

        &__input {
          color: white;
        }

        svg {
          fill-opacity: 0.5;
        }
      }
    }
  }

  &-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 30px 0 0;

    &__container {
      max-width: 1300px;
      margin: auto;
    }

    &__left {
      max-width: 390px;
    }
  }

  &-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 15px;
  }

  &-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #ffffff;
  }
}

.contact {
  &-heading {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 10px;
  }

  &-icons {
    margin-bottom: 8px;

    a {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-mail {
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #ffffff;
  }
}

.mail-list {
  &__heading {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 5px;
  }

  &__subheading {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 10px;
  }
}

.sav-container {
  &__item {
  }

  p {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    min-height: 44px;
  }
}

@media screen and (max-width: $xl2) {
  .footer {
    &-top {
      flex-direction: column;
      align-items: flex-start;
      max-width: 944px;

      &__right {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
      .mail-list {
        margin-right: 0;
      }
    }

    &-bottom {
      &__container {
        max-width: 944px;
      }

      &__left {
      }
    }
  }
}
@media screen and (max-width: $xl) {
  .footer-bottom {
    padding-right: 20px;
    padding-left: 20px;
  }

  .footer-bottom__container {
    padding: 0;
    max-width: 600px;
  }

  .sav-container {
    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      min-height: 35px;
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }

  .footer-top {
    flex-wrap: wrap;
    max-width: 600px;
    padding: 0 0 30px;

    &__left {
      width: 100%;
    }

    &__right {
      margin-top: 40px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
  .mail-list {
    width: 300px;
    &__heading {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: $lg) {
  .footer {
    padding-top: 40px;
    padding-bottom: 46px;
  }
  .footer-top,
  .footer-bottom {
    padding-right: 20px;
    padding-left: 20px;
  }
  .sav-container {
    p {
      font-size: 26px;
      line-height: 32px;
    }
    &__item:first-child {
      margin-bottom: 10px;
    }
  }
  .footer-top__right {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
  }

  .mail-list {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    &__heading {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 5px;
    }
  }
  .footer-bottom {
    &__left {
      display: flex;
      flex-direction: column;
      order: 2;
      width: 100%;
      max-width: initial;
    }

    &__right {
      justify-content: center;
      align-items: center;
      padding-top: 0;
      order: 1;
      width: 100%;
      margin-bottom: 45px;

      .contact-heading {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .footer {
    padding-top: 30px;
    padding-bottom: 45px;
  }
  .footer-top,
  .footer-bottom {
    padding-right: 10px;
    padding-left: 10px;
  }

  .footer-top {
    padding-bottom: 20px;

    &__left {
      margin: 0 auto;
    }

    &__right {
      padding-bottom: 0;

      .sav-container {
        width: 100%;
        order: 2;

        &__item:first-child {
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          min-height: 35px;
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }

      .mail-list {
        width: 100%;
        order: 1;
        text-align: center;
        margin-bottom: 30px;

        &__heading {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        &__subheading {
          margin-bottom: 17px;
        }
      }
    }
  }
  .footer-bottom {
    padding-top: 20px;
  }
}
