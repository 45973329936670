// Colors
$color-white: #ffffff;
$color-yellow: #d6d873;
$color-green: #6bc95b;
$color-dark-green: #0d923a;
$color-turquoise: #2d969a;
$color-blue: #1adce2;
$color-red: #c95b5b;
$color-light-grey: #6e978a;
$color-gray: #4d6655;
$color-green-back: #0b200a;
$color-header-background: rgba(#0c220e, 0.2);
// Fonts
$default-font-family: 'Montserrat', 'PT Sans', 'sans-serif';
$sm: '0px';
$md: '479px';
$lg: '639px';
$xl: '1023px';
$xl2: '1599px';
