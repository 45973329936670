@import './normalize.css';
@import './vars.scss';

* {
}

html {
  font-family: $default-font-family;
  background-color: $color-green-back;
}

body {
  background-color: $color-green-back;
}

#root {
  background-color: $color-green-back;
  min-height: 100vh;
  position: relative;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient(180deg, #0b200a 0%, #113b32 58.58%, #1e5a52 100%);
}

.with-custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6bc95b;
    border-radius: 12px;
  }
}

.fade-transition-enter {
  opacity: 0;
  transform: scale(1.02);
}
.fade-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 250ms;
}
.fade-transition-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-transition-exit-active {
  opacity: 0;
  transform: scale(0.98);
  transition: all 250ms;
}
.fade-transition--appear {
}
.fade-transition--appear-active {
}

.date-picker-element {
  padding: 8px 16px;
  border-radius: 5px;
  background: var(--chakra-colors-gray-200);

  &--wide {
    width: 240px;
  }
}
.react-datepicker-wrapper {
  .react-datepicker__close-icon {
    &:after {
      background-color: var(--chakra-colors-bgGreen-200);
    }
  }
}

@media screen and (max-width: $xl) {
  .table-responsive-wrapper {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

// Fix of Firefox render artifact
.render-animation-with-border {
  animation-name: simpleAnimWithBorder;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  &--blue {
    animation-name: simpleAnimWithBorderBlue;
  }
}

@keyframes simpleAnimWithBorder {
  from {
    box-shadow: 0 6px 11px rgba(0, 0, 0, 0.25);
  }
  to {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  }
}
@keyframes simpleAnimWithBorderBlue {
  from {
    box-shadow: 0 2px 8px #1adce2;
  }
  to {
    box-shadow: 0 2px 16px #1adce2;
  }
}

.render-animation {
  animation-name: simpleAnim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes simpleAnim {
  from {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.01);
  }
  to {
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.01);
  }
}

.puzzle-animation {
  animation-name: puzzlePulseAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes puzzlePulseAnimation {
  from {
    color: var(--chakra-colors-bgGreen-600);
  }
  to {
    color: var(--chakra-colors-green-500);
  }
}

.top-notification {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

// Fix styles for WalletConnect modal
wcm-modal {
  position: fixed;
  z-index: 50000;
}

.slick-slider {
  .slider-image {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    object-fit: cover;
    margin: 0 auto;
    pointer-events: none;
  }

  .slick-list {
    cursor: grab;
    padding-bottom: 20px;

    &:active {
      cursor: grabbing;
    }
  }

  .slick-dots {
    margin-top: 10px;
    li {
      width: 15px;
      height: 15px;

      button::before {
        width: 15px;
        height: 15px;
      }
    }
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    top: 50%;
    display: block;
    width: 60px;
    height: 60px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    z-index: 10;
  }

  .slick-next:before,
  .slick-prev:before {
    content: '';
  }

  .slick-next {
    right: 90px;
    background-image: url(../images/arrow-right.svg) !important;
  }

  .slick-prev {
    left: 90px;
    background-image: url(../images/arrow-left.svg) !important;
  }

  .slick-dots {
    position: static;
    margin-top: 30px;

    li {
      button {
        padding: 0;
        width: 15px;
        height: 15px;
        background: $color-gray;
        border-radius: 50%;

        &::before {
          content: '';
        }
      }

      &.slick-active button {
        background: $color-dark-green;
      }
    }
  }
}

.chakra-popover__popper {
  z-index: 100000 !important;
}

.prevent-select,
.prevent-select > * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
